import '../css/HomeScreen.css'
import { useNavigate } from "react-router-dom";


const HomeScreen = () => {
    const navigate = useNavigate()
    return(
        <div>
            <div class="SplashScreen">
                <div class="SplashScreenText">
                    <h1>Ben Shillito</h1>
                    <h2>Software Engineer</h2>
                </div>
                <div class="SplashScreenButtons">
                    <div onClick={() => (window.scrollTo(0, window.innerHeight))} class="SplashScreenButton">About Me</div>
                    <div onClick={() => (navigate(`/portfolio`))} class="SplashScreenButton">Portfolio</div>
                </div>
            </div>
            <div class="AboutContainer">
                <div class="AboutSection">
                    <h1>About Me:</h1>
                    <p>My name is Ben Shillito and I am a 23 year old Sheffield Hallam Computer Science Graduate and aspiring software engineer.</p>
                    <p>I have interests in Procedural Generation, Simulation, Machine Learning and Cryptography. One of my many inspirations for learning how to code is Tarn Adams, the developer of Dwarf Fortress.</p>
                    <p>During my time studying at university, I learned many important skills. Aside from learning more in depth about Object Oriented programming languages, I learned how to work in a team and collaborate with others.</p>
                    <p>Outside of programming, I am interested in fitness, traveling and Japanese.</p>
                    <p>My goal is to learn more about the software engineering industry and grow overall into an experienced programmer.</p>
                </div>
                
            </div>
            
        </div>
    )
}

export default HomeScreen