import '../css/PortfolioDetails.css'
import Projects from '../assets/Projects'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const PortfolioDetails = () => {
    const params = useParams()
    const PortfolioEntry = Projects.find(data => data.name === params.portfolioID)
    const [currentImage, setCurrentImage] = useState(0)

    function changeImage(){
        setCurrentImage((currentImage+1) % PortfolioEntry.img.length)
            
    }
    window.setTimeout(changeImage, 4000)
    //console.log(PortfolioEntry.description[0])                
    //<img class="PortfolioDetailsImage" src={require(`../assets/${PortfolioEntry.img[currentImage]}`)}></img>

    return(
        <div class="TestComponent">
            <div class="PortfolioDetailsImageContainer">
                <div class="PortfolioDetailsImage" style={{backgroundImage:`url(/img/${PortfolioEntry.img[currentImage]}`}}></div>
                <div class="PortfolioImageTextContainer">
                    <div class="PortfolioImageText">{params.portfolioID}</div>
                </div>
            </div>
            <div class="PortfolioDetailsTextContainer">
                <div class="PortfolioDetailsText">
                    <div class="PortfolioDescriptionTagContainer">
                        {PortfolioEntry.tags.map((tag) => 
                        <div class="PortfolioDescriptionTag">{tag}</div>)}
                    </div>
                    <div class="PorfolioDetailsParagraphs"style={{marginTop:"2%"}}>
                        {PortfolioEntry.description.map((paragraph) => 
                        <>{paragraph}</>)}
                        
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default PortfolioDetails