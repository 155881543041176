import Projects from '../assets/Projects'
import PortfolioEntryList from './PortfolioEntryList'
import PortfolioFilter from './PortfolioFilter'
import { useState, useEffect } from 'react'


const PortfolioView = () => {
    var allTags = []
    const [filterTags, setFilterTags] = useState([])
    const [currentProjects, setCurrentProjects] = useState(Projects)

    const filterResults = () => {
        var projects = Projects
        filterTags.map((tag) => {
            projects = projects.filter(project => 
                project.tags.find(element => element === tag) !== undefined)
            //console.log("projects")
            //console.log(projects[0].tags.find(element => element === tag) !== undefined)
        })
        setCurrentProjects(projects)
    }

    const updateFilterTags = (e) => {
        //console.log(e.target.value)
        //console.log(filterTags.find(element => element === e.target.value))
        if(filterTags.find(element => element === e.target.value) === undefined)
        {
            setFilterTags([...filterTags, e.target.value])
        }
        else{
            var temp = filterTags
            temp.splice(temp.findIndex(element => element === e.target.value),1)
            //console.log(temp)
            setFilterTags(temp)
        }
        filterResults()
        //console.log(filterTags)
    }

    useEffect(() => {


        filterResults()
    }, [filterTags])

    //filterResults()
    for (let index = 0; index < currentProjects.length; index++) {
        //console.log(Projects[index])
        allTags = allTags.concat(currentProjects[index].tags)
    }
    //console.log(allTags)
    return(
        <div style={{minHeight:"90vh", height:"90vh"}}>
            <PortfolioFilter filterTags={allTags} changeFilter={updateFilterTags} currentChecked={filterTags}/>

            <PortfolioEntryList entries={currentProjects}/>
        </div>
    )
}

export default PortfolioView