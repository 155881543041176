import "../css/PortfolioFilter.css"

const PortfolioFilter = ({filterTags, changeFilter, currentChecked}) => {
    var unique = filterTags.filter((v, i, a) => a.indexOf(v) === i)
    //console.log(currentChecked)

    const checkIfChecked = (tag) => {
        if(currentChecked.find(element => element === tag) !== undefined)
        {
            return true
        }
        else
        {
            return false
        }
    }

    return(
        <div class="FilterContainer">
            <h1 class="FilterHeading">Filter:</h1>
            {
                unique.map((item) => (
                <label class="FilterItem" style={{display:"block"}}>
                    <input onChange={changeFilter} type="checkbox" value={item}
                    checked={checkIfChecked(item)} />
                    <span class="checkmark"></span>
                    {item} ({filterTags.filter((v) => (v === item)).length})
                </label>
                ))
            }
        </div>
    )   
}

export default PortfolioFilter