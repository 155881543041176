import PortfolioEntry from './PortfolioEntry' 
import '../css/PortfolioEntryList.css'

const PortfolioEntryList = ({entries}) => {
  return (
    <div class="PortfolioList"style={{width:"80%", height:"90vh", backgroundColor:"#293847", display:"inline-block", overflow:"auto"}}>
      {
        entries.map((entry) => (
          <PortfolioEntry name={entry.name} description={entry.tags} img={entry.img[0]}/>
        ))
      }
        
    </div>
  )
}
export default PortfolioEntryList;