//There needs to be a picture top half, then title bottomhalf
import '../css/PortfolioEntry.css';
import {Link} from 'react-router-dom'

const PortfolioEntry = ({name, description, img}) => {
    const listItems = description.map((descriptionItem) =>
        <div class="EntryDescription">{descriptionItem}</div>
    )
    //<img class="EntryImage"src={require(`../assets/${img}`)} alt={{img}}></img>
    //console.log(img)
    return (
        <Link to={`/portfolio/${name}`} class="EntryContainer">
            <div class="EntryImageContainer">
                <div class="EntryImage" style={{backgroundImage:`url(/img/${img})`}}></div>
            </div>
            <div class="EntryTextContainer">
                <h1 class="EntryTitle">{name}</h1>
                <div class="EntryDescriptionContainer">
                    {listItems}
                </div>

            </div>
        </Link>
    )
}

export default PortfolioEntry;

