import '../css/Footer.css'
import { AiFillLinkedin, AiFillGithub, AiOutlineTwitter } from 'react-icons/ai';


const Footer = () => {
    return(
            <div class="FooterIcons">
                <a href='https://www.linkedin.com/in/benshillito/'><AiFillLinkedin size={40} class="FooterIcon"/></a>
                <a href='https://github.com/benshillito20'><AiFillGithub size={40} class="FooterIcon"/></a>
                <a href='https://twitter.com/BenShillito_'><AiOutlineTwitter size={40} class="FooterIcon"/></a>
            </div>
    )
}

export default Footer