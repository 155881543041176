const Projects = [
    {
        name:"Arenario",
        tags:["Godot","Procedural Generation"],
        img:["Arenario1.jpg", "Arenario2.jpg"],
        description: [<p>Arenario is one of my personal projects that I have been working on for the past year. It is heavily inspired by <a href="https://en.wikipedia.org/wiki/Tarn_Adams">Tarn Adam's</a> <a href="http://www.bay12games.com/dwarves/">Dwarf Fortress</a>.</p>
        ,<p>Arenario is a procedural generation game using the <a href="https://godotengine.org/">Godot</a> game engine and the python-like Godot language. </p>,
        <p>This project includes many complex algorithms and data structures including: <a href="https://en.wikipedia.org/wiki/OpenSimplex_noise">OpenSimplex Noise</a>, <a href="https://en.wikipedia.org/wiki/A*_search_algorithm">A* Pathfinding</a> and Graph Traversal. The point of the project is to generate a Europe-like continent and populate it with procedurally generated cities and cultures similar to those in antiquity.</p>,
        <p>The end goal of this project is to develop a RogueLike where the player can traverse and explore a full procedurally generated world.</p>,
        <p>This is my first large-scale project using Godot and has taught me a lot about how the engine works and how most game engines work.</p>]
    },
    {
        name:"AMES House Price Prediction",
        tags:["Machine Learning","Python", "Jupyter Notebook"],
        img:["AMES1.jpg", "AMES2.jpg", "AMES3.jpg"],
        description:[<p>The final project of the Machine Learning module for my Computer Science course was to develop a Machine Learning system to accurately predict data.</p>,
        <p>For my Project, I decided to utilise the open source <a href="https://www.kaggle.com/competitions/house-prices-advanced-regression-techniques">AMES Housing Price Data Set</a> from Kaggle. This data set has 2919 entries and 81 categories, making it the largest, most complicated data set I had analysed at the time.</p>,
        <p>Part of the requirements for the project were to apply machine learning techniques to analyse the data-set. For this, I used the <a href="https://seaborn.pydata.org/">Seaborn</a> and <a href="https://matplotlib.org/">Matplotlib</a> libraries. I used these libraries to analyse the data's distribution, shape, missing records and highest correlated categories.</p>,
        <p>Next, I processed the data. To do this I first removed outliers from records where there was not enough data to support an accurate prediction. Then, I replaced missing data where appropriate and dropped entries that did not have enough data to be useful. Finally, I analysed the skew of the highest house price correlated categories, and where appropriate, applied a log transformation.</p>,
        <p>To finally pre-process the data, I split the data into a test section and train section. Then, finally, I scaled the data. </p>,
        <p>After this, I used the <a href="https://scikit-learn.org/stable/">Sklearn Linear Regression, Sklearn SVR Regression</a> and <a href="https://keras.io/">Keras Artificial Neural Network</a> algorithms.</p>,
        <p>Finally, I analysed the performance of each algorithm with a histogram and multiple metrics, including mean squared error and mean absolute error.</p>]
    },
    {
        name:"IBM VR Meeting Environment",
        tags:["JavaScript","Vue", "A-frame"],
        img:["IBM1.jpg", "IBM2.jpg", "IBM3.jpg"],
        description: [<p>During my second year at Sheffield Hallam, one of the main projects we were tasked with was to work directly with a client to design and develop a system they would be happy with.</p>,
        <p>I signed up to be on the team to develop a virtual reality meeting environment for IBM. Our task was broken down into two weeks; before and after each week we would have a virtual meeting with our client to discuss design decisions.</p>,
        <p>Before we started the project, as a group, we researched the potential technologies that could be useful for the project. We decided to use Vue as our main JavaScript framework, and A-Frame to handle the graphics.</p>,
        <p>This project was excellent for developing my teamwork and inter-personal skills. The course I was on mainly focused on technical skills and learning programming fundamentals; working on a project like this was completely different, and helped me develop as a programmer.</p>]
    },
    {
        name:"Personal Trainer Mobile App",
        tags:["JavaScript","React-Native", "Android Studio"],
        img:["PersonalTrainerApp1.jpg", "PersonalTrainerApp2.jpg",  "PersonalTrainerApp3.jpg"],
        description: [<p>For my final project at university, I decided to create a mobile app. During my time at university, I had very little experience doing anything mobile. As such a large portion of the software engineering industry creates software for mobile devices, I decided it would be a good idea to work with this technology.</p>,
        <p>I decided to use what I know about weightlifting to create an app aimed at training personal trainers. I also decided to take a procedural approach to generating educational scenarios using the seeded approach.</p>,
        <p>I used React Native as a framework to develop the project, as I already had some experience using React. I used Expo Cli to link my code to an Android emulator, as well as prototype it on real phones.</p>]
    },

]


export default Projects