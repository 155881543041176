import './App.css';
import './css/main.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import PortfolioView from './components/PortfolioView'
//import TestComponent from './components/TestComponent'
import PortfolioDetails from './components/PortfolioDetails'
import NavigationBar from './components/NavigationBar'
import HomeScreen from './components/HomeScreen'

function App() {
  return (
    <div style={{overflowY:"hidden"}}>

      <Router>
        <NavigationBar/>
        <Routes>
          <Route path="" element={<HomeScreen />}></Route>
          <Route path="/portfolio" element={<PortfolioView />}></Route>
          <Route path="/portfolio/:portfolioID" element={<PortfolioDetails />}></Route>
        </Routes>
      </Router>
    </div>
  );
}
//          <Route path="/contact" element={<Contact />}></Route>

export default App;
