import '../css/NavigationBar.css'
import { Link } from "react-router-dom";
import Footer from './Footer'



const NavigationBar = () => {

    //const navigate = useNavigate()

    return(
        <div class="NavigationBar">
            <Link to={"../"} class="NavigationBarItem">Home</Link>
            <Link to={"/portfolio"} class="NavigationBarItem">Portfolio</Link>
            <Footer />
        </div>
    )
}

export default NavigationBar